import React, { Component } from "react";
import { Space } from "antd";

export default class When extends Component {
  render() {
    return (
      <div>
        <Space direction="vertical" size="large">
          <div>
            如果选择短期游学，那随时都可以出发，选择合适的游学项目即可。在考虑长期留学前，先选择短期出国游历一番，对于后续的决定起着至关重要的作用。
          </div>
          <div>
            对于长期留学，关于什么时候出国留学最好，有多种说法，我们认为每个年龄段都可以留学，主要看您最重要的留学目标是什么。
          </div>
          <Space direction="vertical">
            <div className="title_small">小学之前</div>
            <div>英语变为母语，完全融入西方文化，对中国比较陌生。</div>
            <div className="title_small">小学三四年级</div>
            <div>
              中文的汉字都学完了，基本阅读能力具备，但对中国文化理解不深。英语学习基本母语水平，完全可融入西方文化。
            </div>
            <div className="title_small">初高中阶段</div>
            <div>
              <div>
                已经学习了文言文和一定量的古诗词，也基本可以理解中文书籍，很容易对中国文化产生浓厚的兴趣，中国文化根基不会丢。
              </div>
              <div>
                读过国内的小学、中学，基本的社会化已经完成，可以基本理解中国人的人情世故，在国内有一些朋友，有兴趣关注国内同龄人的状况，未来无论何时都可以轻松地和中国人交往。
              </div>
              <div>
                中学阶段开始深入学习英语并不算晚，如果用功一些，多实践练习多读英文书籍，也是完全可以达到接近母语程度的。
              </div>
              <div>
                国内的数学基础教育扎实，为中学的物理化学的学习打好了基础，为高GPA打下坚实基础。
              </div>
              <div>
                西式教育的中学阶段课业压力不大，摆脱中式教育的“重复无产出”的刷题任务，有时间和机会发现自己的天赋和兴趣所在，在申请大学前确立未来的职业方向。
              </div>
            </div>
            <div className="title_small">大学阶段</div>
            <div>
              高中毕业的孩子基本已经成人，可以更好的照顾自己，国外的大学教育质量更胜一筹，此时，英语和文化适应能力需要提前多做准备，否则大学生活压力巨大。
            </div>
            <div className="title_small">研究生阶段</div>
            <div>
              出国攻读硕士或博士，费用性价比高，目标更明确，以更成熟的心态学习和了解异国文化，形成更客观的对比。
            </div>
          </Space>
          <div>
            我们的终极目标是，希望孩子们能体验更丰富多彩的人生，能始终保持一颗敬畏的心去看待自己的文化和他人的文化。
          </div>
        </Space>
      </div>
    );
  }
}
