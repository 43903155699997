import React, { Component } from "react";
import { Space, Steps } from "antd";
const { Step } = Steps;
export default class How extends Component {
  render() {
    return (
      <div>
        <Space direction="vertical" size="large">
          <div>
            去异国游学不同于旅游，还是需要办理一系列的申请和手续，还需要办理不同类型的签证的
          </div>
          <Steps type="default" direction="vertical" current={6}>
            <Step
              title="决定游学"
              description="这是一个重大的决策，请慎重考虑！"
            />
            <Step
              title="游学规划"
              description="咨询朋友、机构，确定游学具体规划"
            />
            <Step
              title="申请游学"
              description="向心仪的学校发申请，可申请多所"
            />
            <Step title="申请签证" description="向移民局申请相应类型的签证" />
            <Step
              title="落地规划"
              description="提前安排机票、落地后的食宿安排等"
            />
            <Step
              title="准备出发"
              description="带上护照、机票、必需品，出发！！！"
            />
          </Steps>
        </Space>
      </div>
    );
  }
}
