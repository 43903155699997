import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Layout, Space, Divider, Image, BackTop, Row, Col } from "antd";
import Store from "./redux/store";
import Home from "./pages/home";
import Aboutus from "./pages/aboutus";
import Contactus from "./pages/contactus";
import Services from "./pages/services";
import Activities from "./pages/activities";
import Reading from "./pages/reading";
import Going from "./pages/going";
import HZMenu from "./components/hzmenu";
import logoImg from "./assets/images/logo.gif";
import wechatccImg from "./assets/images/haizongcc.jpg";
import wechatcompanyImg from "./assets/images/haizongcompany.jpg";
import "./App.less";

const { Header, Content, Footer } = Layout;

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WholePage />}>
          <Route path="/home" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/services" element={<Services />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/reading" element={<Reading />} />
          <Route path="/going" element={<Going />} />
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
        </Route>
        <Route path="*" element={<WholePage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

function WholePage() {
  return (
    <Provider store={Store}>
      <Layout className="layout">
        <Header className="header">
          <Row gutter={0}>
            <Col span={1}></Col>
            <Col xs={20} sm={20} md={6} lg={6} xl={8}>
              <div className="logo">
                <Space>
                  <Image src={logoImg} width={30} className="img" />
                  <div className="txt">海踪文化传播</div>
                </Space>
              </div>
            </Col>
            <Col xs={2} sm={2} md={16} lg={16} xl={14}>
              <HZMenu className="menu" />
            </Col>
            <Col span={1}></Col>
          </Row>
        </Header>
        <Content className="content">
          <Row gutter={0}>
            <Col span={1}></Col>
            <Col span={22}>
              <Outlet />
              <BackTop />
            </Col>
            <Col span={1}></Col>
          </Row>
        </Content>
        <Footer className="footer">
          <Row gutter={0}>
            <Col span={1}></Col>
            <Col xs={7} sm={11} md={16} lg={15} xl={17}>
              <HZMenu className="menu" />
            </Col>
            <Col xs={15} sm={11} md={6} lg={7} xl={5}>
              <div className="wechatlogo">
                <Space size="small">
                  <Space direction="vertical" align="center">
                    <Image src={wechatccImg} width={100} height={100} />
                    <div align="center">
                      <p>
                        在线咨询
                        <br />
                        了解报名优惠
                      </p>
                    </div>
                  </Space>
                  <Space direction="vertical" align="center">
                    <Image src={wechatcompanyImg} width={100} height={100} />
                    <div align="center">
                      <p>
                        海踪服务号
                        <br />
                        关注更多资讯
                      </p>
                    </div>
                  </Space>
                </Space>
              </div>
            </Col>
            <Col span={1}></Col>
          </Row>
          <Divider />
          <Row gutter={0} className="bottom-title">
            <Col span={1}></Col>
            <Col span={22}>
              <Row>
                <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                  ©2023 上海海踪文化传播有限公司
                </Col>
                <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                  <a
                    href="https://beian.miit.gov.cn/#/Integrated/index"
                    target="_blank"
                    rel="noreferrer"
                  >
                    沪ICP备20001080号-1
                  </a>
                </Col>
              </Row>
            </Col>

            <Col span={1}></Col>
          </Row>
        </Footer>
      </Layout>
    </Provider>
  );
}
