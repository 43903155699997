import React, { Component } from "react";
import { Space, Table } from "antd";
const dataSource = [
  {
    key: "1",
    name: "美国",
    school: "可选范围多学校排名高",
    cost: "昂贵",
    weather: "依赖城市",
    food: "依赖城市",
    safty: "不理想",
    work: "难",
    with: "不方便",
  },
  {
    key: "2",
    name: "加拿大",
    school: "可选范围少学校排名高",
    cost: "适中",
    weather: "偏冷",
    food: "中餐丰富",
    safty: "安全",
    work: "容易",
    with: "方便",
  },
  {
    key: "3",
    name: "英国",
    school: "可选范围少学校排名高",
    cost: "费用高",
    weather: "一般",
    food: "中餐一般",
    safty: "一般",
    work: "非常难",
    with: "不方便",
  },
  {
    key: "4",
    name: "澳大利亚",
    school: "可选范围少学校排名高",
    cost: "适中",
    weather: "宜人",
    food: "中餐一般",
    safty: "治安好",
    work: "一般",
    with: "方便",
  },
];
const columns = [
  {
    title: "国家",
    dataIndex: "name",
    key: "name",
    width: 90,
  },
  {
    title: "教育资源",
    dataIndex: "school",
    key: "school",
    width: 110,
  },
  {
    title: "费用",
    dataIndex: "cost",
    key: "cost",
  },
  {
    title: "气候",
    dataIndex: "weather",
    key: "weather",
  },
  {
    title: "饮食",
    dataIndex: "food",
    key: "food",
  },
  {
    title: "治安",
    dataIndex: "safty",
    key: "safty",
  },
  {
    title: "移民",
    dataIndex: "work",
    key: "work",
  },
  {
    title: "陪读",
    dataIndex: "with",
    key: "with",
  },
];
export default class Where extends Component {
  render() {
    return (
      <Space direction="vertical">
        <div>下面是各国留学的优劣势对比，供大家了解，一起来看看吧！</div>
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
        />
        <div>综合比较来看，加拿大留学是性价比很高的选择。</div>
      </Space>
    );
  }
}
