import React, { Component } from "react";
import { Collapse, List, Card } from "antd";
import wechatImg from "../../../assets/images/haizongcc.jpg";

export default class Shorttermlearningcamp extends Component {
  render() {
    const { Panel } = Collapse;
    return (
      <div>
        <Collapse defaultActiveKey={["title"]}>
          <Panel header="加拿大青少年短期游学" key="title">
            <p>
              进入温哥华公办学校一段时间，了解北美不同的教育体系，体验真实的加拿大学校生活，并在自然的学习环境中发展英语。
            </p>
          </Panel>
          <Panel header="活动特色" key="special">
            <Card>
              <List>
                <List.Item>此学习为非学分制课程</List.Item>
                <List.Item>根据学生英语水平和学校座位情况安排班级</List.Item>
                <List.Item>
                  进入当地学生课堂插班，如果英语水平有限，某些课程可能无法参与，英语水平提高后可再安排
                </List.Item>
              </List>
            </Card>
          </Panel>
          <Panel header="活动时间：9月-12月 1月-3月 3月-6月" key="time">
            <p>
              <ul>
                <li>温哥华正常上课时间皆可申请，但需要提前确定是否有学位</li>
                <li>1-5个月可选，超过6个月请参见 长期留学</li>
              </ul>
            </p>
          </Panel>
          <Panel header="活动城市：温哥华" key="city">
            <p>加拿大 温哥华</p>
          </Panel>
          <Panel header="适合人群：幼儿园大班 - 12年级学生" key="person">
            <p>
              <ul>
                <li>学生：幼儿园大班 - 12年级</li>
              </ul>
            </p>
          </Panel>
          <Panel header="日程安排" key="schedule">
            <Card bordered={false}>
              周一至周五全天上课，8:30-9:00上学 ～
              3:00-3:30放学，完全当地孩子的学习时间安排和课表规划
            </Card>
          </Panel>

          <Panel header="其他说明" key="other">
            <List>
              <List.Item extra={<img width={100} src={wechatImg} />}>
                <p>
                  <ul style={{ lineHeight: 2 }}>
                    <li>
                      非学校学习的下午放学后和周末时间，完全自己掌控，如需进行城市游览等活动请微信咨询
                    </li>
                    <li>
                      8年级及以上可申请寄宿家庭，低年级学生需要有父母或老师或其他监护人监护
                    </li>
                    <li>
                      可提供机场接送、日常用车、民居酒店预定、机票预定、保险购买、签证办理等服务
                    </li>
                    <li>团队预定有适当优惠</li>
                  </ul>
                </p>
              </List.Item>
            </List>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
