import React, { Component } from "react";
import { Row, Col, Image, Space } from "antd";
import mainImg from "../assets/images/aboutus.jpg";
import "./pages.less";

export default class Aboutus extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col span={24}>
            <img src={mainImg} className="mainImg" />
          </Col>
        </Row>
        <div className="main">
          <Space direction="vertical" size="large">
            <div className="title_big">海踪</div>
            <div>
              上海海踪文化传播有限公司是一家致力于中西文化交流和传播的信息咨询有限公司。
              我们希望能引进更多的西方先进的文化理念、成熟的教育资源和高质量的交流活动。
            </div>
            <div className="title_big">行万里路，读万卷书</div>
            <div>
              众所周知，“见识”在一个人的成长中起着至关重要的作用，行万里路，就是“见”，读万卷书，就是“识”，游学将这两件事完美的结合起来了。
            </div>
            <Space direction="vertical">
              <div className="title_big">海踪理念</div>
              <div>
                我们相信有一些简单朴素的价值观，吸引着和我们志趣相投的人，共同游历世界，共同成长进步。
              </div>
              <div className="title_small">追寻自我</div>
              <div>
                路漫漫其修远兮，吾将上下而求索。对一个年轻人而言最重要的是发现自己的兴趣，促进个人潜能的发挥，尽早确定人生努力的方向。
              </div>
              <div className="title_small">追逐梦想</div>
              <div>
                朝闻道，夕死可矣。梦想是对美好生活的憧憬，有梦想，才是真正意义上的生活。努力追逐梦想吧，不管结果如何，努力过才不会后悔。
              </div>
              <div className="title_small">追求卓越</div>
              <div>
                欲穷千里目，更上一层楼。卓越，并不是完美，而是不断完善自我、不断超越自我，努力去追求完美的过程。
              </div>
            </Space>
            <div className="title_big">循四海之踪，观世界之大，启未来之门</div>
            <div>
              行万里路，读万卷书。以梦为马，不负韶华。青春年少，天涯海角，世界那么大，我想去看看。希望海踪可以助您一臂之力。
            </div>
          </Space>
        </div>
      </div>
    );
  }
}
