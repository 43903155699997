import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Layout, Menu } from "antd";
import { setMenu } from "../redux/actions";
import { UnorderedListOutlined } from "@ant-design/icons";

const menuList = [
  { path: "/home", title: "首页" },
  { path: "/aboutus", title: "关于我们" },
  { path: "/services", title: "游学中心" },
  // { path: "/reading", title: "读万卷书" },
  // { path: "/going", title: "行万里路" },
  { path: "/activities", title: "近期活动" },
  { path: "/contactus", title: "联系我们" },
];

class HZMenu extends Component {
  state = {
    menuWidth: "650",
  };
  handleClick = (e) => {
    this.props.setMenu(e.key);
  };
  render() {
    const current = this.props.hzMenu;
    const { menuWidth } = this.state;
    return (
      <Layout style={{ backgroundColor: "rgba(255, 255, 255,0)" }}>
        <Layout.Sider
          style={{
            backgroundColor: "rgba(255, 255, 255,0)",
          }}
          width={"100%"}
          breakpoint="md"
          collapsedWidth={menuWidth}
          zeroWidthTriggerStyle={
            {
              // backgroundColor: "rgb(59, 99, 156)",
              // marginTop: -55,
              // marginRight: 0,
              // position: "absolute",
              // float: "right",
            }
          }
          onBreakpoint={(broken) => {
            if (broken) {
              this.setState({ menuWidth: "50" });
            } else {
              this.setState({ menuWidth: "650" });
            }
          }}
        >
          <Menu
            mode="horizontal"
            onClick={this.handleClick}
            selectedKeys={current}
            overflowedIndicator={
              <UnorderedListOutlined style={{ fontSize: "20px" }} />
            }
            // style={{ backgroundColor: "red" }}
            {...this.props}
          >
            {menuList.map((item) => (
              <Menu.Item key={item.path}>
                <Link to={item.path}>{item.title}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Layout.Sider>
      </Layout>
    );
  }
}
export default connect((state) => ({ hzMenu: state.hzMenu }), { setMenu })(
  HZMenu
);
