import React, { Component } from "react";
import { Collapse, List, Card, Row, Col } from "antd";
import parentcampstudentschedualImg from "../../../assets/images/activities/parentcampstudentschedual.jpg";
import parentcampparentschedualImg from "../../../assets/images/activities/parentcampparentschedual.jpg";
import parentcampstudentactivityImg from "../../../assets/images/activities/parentcampstudentactivity.jpg";
import parentcampparentactivityImg from "../../../assets/images/activities/parentcampparentactivity.jpg";
import wechatImg from "../../../assets/images/haizongcc.jpg";
import summerparentImg from "../../../assets/images/summerparent.jpg";
import reactDom from "react-dom";

export default class Summerparentscamp extends Component {
  render() {
    const { Panel } = Collapse;
    return (
      <div>
        <Collapse defaultActiveKey={["title"]}>
          <Panel header='2023年加拿大暑期“亲子寄宿"夏令营' key="title">
            <List>
              <List.Item extra={<img width={150} src={summerparentImg} />}>
                <List.Item.Meta
                  description="“亲子寄宿”夏令营为来自世界各地的年轻学生和家庭提供一次一生的加拿大学习冒险之旅!
                  学生和家长完全沉浸在一个以学习为中心的环境中，通过课堂内外的实际任务和现实生活中的社会情景，
                  持续的学习和练习英语，同时玩得开心，结交朋友，体验加拿大的生活和文化!"
                ></List.Item.Meta>
              </List.Item>
            </List>
          </Panel>
          <Panel header="活动特色" key="special">
            <Card>
              <List>
                <List.Item>
                  和来自85个国家的学生和家庭互动，只能使用英语原则，快速提升语言能力和沟通能力
                </List.Item>
                <List.Item>
                  入住加拿大当地人家中，体验真实的加拿大多元文化魅力
                </List.Item>
                <List.Item>
                  参观温哥华著名景区，游览落基山脉国家公园，欣赏迷人的北美风光
                </List.Item>
              </List>
            </Card>
          </Panel>
          <Panel header="活动时间：2023年 7月4日 - 8月19日" key="time">
            <p>
              <ul>
                <li>起始时间：2023年 7月4日 - 8月19日</li>
                <li>整个活动共7周，2周 - 7周可选</li>
              </ul>
            </p>
          </Panel>
          <Panel header="活动城市：温哥华" key="city">
            <p>加拿大 温哥华</p>
          </Panel>
          <Panel header="适合人群：学生 家长" key="person">
            <p>
              <ul>
                <li>学生：9-17岁</li>
                <li>成人：孩子家长</li>
              </ul>
            </p>
          </Panel>
          <Panel header="日程安排" key="schedule">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <p>学生每日时间表</p>
                <p>
                  <img width={"100%"} src={parentcampstudentschedualImg} />
                </p>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <p>家长每日时间表</p>
                <p>
                  <img width={"100%"} src={parentcampparentschedualImg} />
                </p>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <p>学生各周活动安排</p>
                <p>
                  <img width={"100%"} src={parentcampstudentactivityImg} />
                </p>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12}>
                <p>家长各周活动安排</p>
                <p>
                  <img width={"100%"} src={parentcampparentactivityImg} />
                </p>
              </Col>
            </Row>
          </Panel>
          <Panel header="其他说明" key="other">
            <List>
              <List.Item extra={<img width={100} src={wechatImg} />}>
                <p>
                  <ul style={{ lineHeight: 2 }}>
                    <li>
                      下午活动和周末活动，可能会根据天气等原因有所调整，详细情况请微信咨询
                    </li>
                    <li>
                      可提供机场接送、日常用车、民居酒店预定、机票预定、保险购买、签证办理等服务
                    </li>
                    <li>团队预定有适当优惠</li>
                  </ul>
                </p>
              </List.Item>
            </List>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
