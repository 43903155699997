import React, { Component } from "react";
import { Collapse, List, Card } from "antd";
import wechatImg from "../../../assets/images/haizongcompany.jpg";

export default class Longtermlearningcamp extends Component {
  render() {
    const { Panel } = Collapse;
    return (
      <div>
        <Collapse defaultActiveKey={["title"]}>
          <Panel header="加拿大青少年长期留学" key="title">
            <p>
              进入温哥华公办学校学习，在自然的学习环境中发展英语，在北美教育体系中建立批判性和创造性思维，培养良好的沟通能力和强烈的社会责任感。
            </p>
          </Panel>
          <Panel header="活动特色" key="special">
            <Card>
              <List>
                <List.Item>此学习为学分制课程</List.Item>
                <List.Item>
                  根据学生年龄、申请意愿和学校座位情况安排学校和班级
                </List.Item>
                <List.Item>
                  如果英语水平有限，先参加ESL语言班提升英语，此时某些课程可能无法参与，待英语水平提高后再安排全部课程
                </List.Item>
              </List>
            </Card>
          </Panel>
          <Panel header="活动时间：9月-12月 1月-3月 3月-6月" key="time">
            <p>
              <ul>
                <li>
                  一般每年11月开始申请下一年度的入学，热门学校名额紧张，需要提前准备
                </li>
                <li>至少半年、一年或一年以上</li>
              </ul>
            </p>
          </Panel>
          <Panel header="活动城市：温哥华" key="city">
            <p>加拿大 温哥华</p>
          </Panel>
          <Panel header="适合人群：幼儿园大班 - 12年级学生" key="person">
            <p>
              <ul>
                <li>学生：幼儿园大班 - 12年级</li>
              </ul>
            </p>
          </Panel>
          <Panel header="日程安排" key="schedule">
            <Card bordered={false}>
              周一至周五全天上课，8:30-9:00上学 ～
              3:00-3:30放学，完全当地孩子的学习时间安排和课表规划
            </Card>
          </Panel>

          <Panel header="其他说明" key="other">
            <List>
              <List.Item extra={<img width={100} src={wechatImg} />}>
                <p>
                  <ul style={{ lineHeight: 2 }}>
                    <li>该类留学需要申请学习签证</li>
                    <li>
                      8年级及以上可申请寄宿家庭，低年级学生需要有父母或其他监护人监护
                    </li>
                    <li>
                      可提供机场接送、日常用车、民居酒店预定、机票预定、保险购买、签证办理等服务
                    </li>
                    <li>长期留学是家庭中的重要决定，请微信咨询后慎重决策</li>
                  </ul>
                </p>
              </List.Item>
            </List>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
