import React, { Component } from "react";
import { Row, Col, Space, List } from "antd";
import mainImg from "../assets/images/main.jpg";
import summerlearnImg from "../assets/images/summerlearn.jpg";
import summerleadershipImg from "../assets/images/summerleadership.jpg";
import summerparentImg from "../assets/images/summerparent.jpg";
import englishlearnImg from "../assets/images/englishlearn.jpg";

export default class Home extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col span={24}>
            <img src={mainImg} className="mainImg" />
          </Col>
        </Row>
        <div className="main">
          <Space direction="vertical" size="large">
            <div>
              <p style={{ fontSize: 16 }}>
                上海海踪文化传播有限公司是一家致力于中西文化交流和传播的信息咨询有限公司。
                我们希望能引进更多的西方先进的文化理念、成熟的教育资源和高质量的交流活动。
              </p>
              <p style={{ fontSize: 18 }}>近期活动</p>
              <List>
                <List.Item extra={<img width={150} src={summerlearnImg} />}>
                  <List.Item.Meta
                    style={{ paddingRight: 10 }}
                    title='2023年加拿大暑期“语言学习+多元文化"夏令营'
                    description="与来自世界各地的同伴共同学习，实践中提升英语听力、口语和阅读技能。
                与当地家庭共同生活，了解加拿大的真实生活、欣赏迷人的北美风景。
                实用的英语技能的提升、朴实的多元文化，相信一定为您带来一段难忘的记忆。"
                  ></List.Item.Meta>
                </List.Item>
                <List.Item
                  extra={<img width={150} src={summerleadershipImg} />}
                >
                  <List.Item.Meta
                    style={{ paddingRight: 10 }}
                    style={{ paddingRight: 10 }}
                    title='2023年加拿大暑期“全球领导力"夏令营'
                    description="和我们一起住在世界知名的UBC大学，和来自世界各地的新朋友共同学习英语，培养领导技能，一起欣赏迷人的北美落基山脉风光。"
                  ></List.Item.Meta>
                </List.Item>
                <List.Item extra={<img width={150} src={summerparentImg} />}>
                  <List.Item.Meta
                    style={{ paddingRight: 10 }}
                    title='2023年加拿大暑期“亲子寄宿"夏令营'
                    description="“亲子寄宿”夏令营为来自世界各地的年轻学生和家庭提供一次一生的加拿大学习冒险之旅! 学生和家长完全沉浸在一个以学习为中心的环境中，通过课堂内外的实际任务和现实生活中的社会情景， 持续的学习和练习英语，同时玩得开心，结交朋友，体验加拿大的生活和文化!"
                  ></List.Item.Meta>
                </List.Item>
                <List.Item extra={<img width={150} src={englishlearnImg} />}>
                  <List.Item.Meta
                    title="体验式游学"
                    description={
                      <p>
                        <ul
                          style={{
                            lineHeight: 2,
                            listStyleType: "none",
                            marginLeft: -40,
                          }}
                        >
                          <li>背上行囊，来一场说走就走的旅行吧！</li>
                          <li>
                            白天与来自世界各地的同学一起学习英语，下午课后一同去酒吧放松聊天。
                          </li>
                          <li>
                            傍晚回到寄宿的当地家庭，温暖的房间、异域的美食，也许还有一只猫徘徊在你的脚下。
                          </li>
                          <li>
                            周末和同学到downtown市区逛逛，亦或和寄宿家庭来一场沙滩BBQ！
                          </li>
                          <li>
                            我更愿，独自一人坐在海边，欣赏温哥华落日，想象对岸的家人正在开始忙碌的一天......
                          </li>
                        </ul>
                      </p>
                    }
                  ></List.Item.Meta>
                </List.Item>
              </List>
            </div>
          </Space>
        </div>
      </div>
    );
  }
}
