import React, { Component } from "react";
import { Space, Row, Col, Image } from "antd";
import canadaImg from "./images/canada.jpg";

export default class Canada extends Component {
  render() {
    return (
      <div>
        <div className="title">加拿大</div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Space size="large" direction="vertical">
              <div>
                加拿大（Canada），位于北美洲北部，东临大西洋，西濒太平洋，西北部邻美国阿拉斯加州，南接美国本土，北靠北冰洋。
                加拿大国土面积998万平方千米，居世界第二位。截至2019年11月，加拿大共设10省3地区，首都为渥太华。
                截至2020年10月，加拿大人口为3800万。
              </div>
              <div>
                加拿大是高度发达的资本主义国家，是世界工业大国和西方七大工业国之一，制造业和高科技产业较发达，
                资源工业、初级制造业和农业为国民经济的主要支柱。
              </div>
              <div>
                得益于丰富的天然资源，加拿大是经济发达、国民富裕的国家。加拿大的人均国内生产总值在全世界排名第十六，
                人类发展指数排名第十。2021年，加拿大国内生产总值为20900亿加元，
                它在教育、政府透明度、自由度、生活品质及经济自由的国际排名都名列前茅，被评为2021年最佳国家。
              </div>
              <div>
                加拿大的气候是大陆性气候，该国大部分处于极地，冬天长期严寒、夏天短暂、春季和秋季极短。
                落基山脉对来自太平洋的缓和气流起了屏障的作用，故只有落基山脉以西地区（不列颠哥伦比亚省），享有相对温和的气候。
              </div>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Image src={canadaImg} width={"100%"} style={{ padding: 10 }} />
          </Col>
        </Row>
      </div>
    );
  }
}
