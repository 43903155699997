import React, { Component } from "react";
import { Collapse, List, Card, Row, Col } from "antd";
import englishlearnclassImg from "../../../assets/images/activities/englishlearnclass.jpg";
import englishlearnschedualImg from "../../../assets/images/activities/englishlearnschedual.jpg";
import englishlearntimeImg from "../../../assets/images/activities/englishlearntime.jpg";
import englishlearntourImg from "../../../assets/images/activities/englishlearntour.jpg";
import englishlearnactivityImg from "../../../assets/images/activities/englishlearnactivity.jpg";
import englishlearntypeImg from "../../../assets/images/activities/englishlearntype.jpg";
import accomodationImg from "../../../assets/images/activities/accommodation.jpg";
import wechatImg from "../../../assets/images/haizongcompany.jpg";
import englishlearnImg from "../../../assets/images/englishlearn.jpg";

export default class Englishlearningcamp extends Component {
  render() {
    const { Panel } = Collapse;
    const gridStyle = {
      width: "33%",
      textAlign: "center",
    };
    const metaStyle = {
      padding: 5,
      textAlign: "center",
    };
    const cardStyle = {
      backgroundColor: "rgb(250,250,250)",
    };
    const cardheadStyle = {
      color: "rgb(59, 99, 156)",
      fontWeight: "bold",
    };
    return (
      <div>
        <Collapse defaultActiveKey={["title"]}>
          <Panel header="体验式游学" key="title">
            <List>
              <List.Item extra={<img width={100} src={englishlearnImg} />}>
                <List.Item.Meta
                  description={
                    <p>
                      <ul style={{ lineHeight: 2, listStyleType: "none" }}>
                        <li>背上行囊，来一场说走就走的旅行吧！</li>
                        <li>
                          白天与来自世界各地的同学一起学习英语，下午课后一同去酒吧放松聊天。
                        </li>
                        <li>
                          傍晚回到寄宿的当地家庭，温暖的房间、异域的美食，也许还有一只猫徘徊在你的脚下。
                        </li>
                        <li>
                          周末和同学到downtown市区逛逛，亦或和寄宿家庭来一场沙滩BBQ！
                        </li>
                        <li>
                          我更愿，独自一人坐在海边，欣赏温哥华落日，想象对岸的家人正在开始忙碌的一天......
                        </li>
                      </ul>
                    </p>
                  }
                ></List.Item.Meta>
              </List.Item>
            </List>
          </Panel>
          <Panel header="活动特色" key="special">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8}>
                <Card
                  style={cardStyle}
                  headStyle={cardheadStyle}
                  hoverable={true}
                  bordered={false}
                  size="small"
                  title="沉浸式英语学习"
                >
                  <List size="small" bordered={false}>
                    <List.Item>来自全世界不同肤色的同学</List.Item>
                    <List.Item>基础/商务/语言教师认证英语</List.Item>
                    <List.Item>体验北美不同教育模式</List.Item>
                    <List.Item>了解西式批评性思维和个性发展</List.Item>
                  </List>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Card
                  style={cardStyle}
                  headStyle={cardheadStyle}
                  hoverable={true}
                  bordered={false}
                  size="small"
                  title="入住当地家庭"
                >
                  <List size="small">
                    <List.Item>体验真实加拿大生活</List.Item>
                    <List.Item>提升跨文化交际能力</List.Item>
                    <List.Item>感受加拿大多元文化</List.Item>
                    <List.Item>开拓提升国际视野</List.Item>
                  </List>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Card
                  style={cardStyle}
                  headStyle={cardheadStyle}
                  hoverable={true}
                  bordered={false}
                  size="small"
                  title="游览休闲总相宜"
                >
                  <List size="small" split={false}>
                    <List.Item>尝试本地户外活动</List.Item>
                    <List.Item>游览温哥华特色景区</List.Item>
                    <List.Item>领略不同的社区文化</List.Item>
                    <List.Item>体验北美轻奢购物</List.Item>
                  </List>
                </Card>
              </Col>
            </Row>
          </Panel>
          <Panel header="活动时间：一年四季" key="time">
            <p>
              <ul>
                <li>起始时间：每周一入学</li>
                <li>每周启动，1周 - 24周可选</li>
              </ul>
            </p>
          </Panel>
          <Panel header="活动城市：温哥华 / 维多利亚 / 多伦多" key="city">
            <p>加拿大 温哥华 / 维多利亚 / 多伦多</p>
          </Panel>
          <Panel header="适合人群：青少年学生、成人" key="person">
            <p>
              <ul>
                <li>青 少 年：13-18岁</li>
                <li>
                  成 年 人：大学生，职场人士，陪读家长，想学习成长的任何人
                </li>
              </ul>
            </p>
          </Panel>
          <Panel header="活动介绍" key="introduction">
            <Row gutter={[16, 16]}>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                xl={{ span: 8, order: 1 }}
              >
                <Card
                  bodyStyle={{ padding: 0 }}
                  cover={
                    <img style={{ width: "100%" }} src={englishlearnclassImg} />
                  }
                >
                  <Card.Meta
                    title="分级学习"
                    style={metaStyle}
                    description={
                      <div>
                        <p>入学时进行英语水平测试</p>
                        <p>根据测试结果分级学习</p>
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 12, order: 2 }}
                xl={{ span: 8, order: 2 }}
              >
                <Card
                  bodyStyle={{ padding: 0 }}
                  cover={
                    <img style={{ width: "100%" }} src={englishlearntimeImg} />
                  }
                >
                  <Card.Meta
                    title="时间安排"
                    style={metaStyle}
                    description={
                      <div>
                        <p>每周5天，9:00开始，3:00结束</p>
                        <p>周末：寄宿家庭活动 / 学校活动 / 自由活动</p>
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col
                xs={{ span: 24, order: 3 }}
                sm={{ span: 24, order: 3 }}
                md={{ span: 12, order: 3 }}
                lg={{ span: 12, order: 3 }}
                xl={{ span: 8, order: 3 }}
              >
                <Card
                  bodyStyle={{ padding: 0 }}
                  cover={
                    <img
                      style={{ width: "100%" }}
                      src={englishlearnschedualImg}
                    />
                  }
                >
                  <Card.Meta
                    title="灵活学习安排"
                    style={metaStyle}
                    description={
                      <div>
                        <p>每天只选择1-5小时学习 或 全天学习</p>
                        <p>下午有课后活动与学习辅导，自愿参加</p>
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col
                xs={{ span: 24, order: 5 }}
                sm={{ span: 24, order: 5 }}
                md={{ span: 12, order: 5 }}
                lg={{ span: 12, order: 5 }}
                xl={{ span: 8, order: 4 }}
              >
                <Card
                  bodyStyle={{ padding: 0 }}
                  cover={
                    <img
                      style={{ width: "100%" }}
                      src={englishlearnactivityImg}
                    />
                  }
                >
                  <Card.Meta
                    style={metaStyle}
                    title="课后活动"
                    description="温哥华水族馆、酒吧之夜、冰壶/滑冰、海滩烧烤、皮划艇、赤柱公园单车行、徒步旅行、加入足球队、冰球比赛、水上飞机之旅、感恩节活动、滑雪等"
                  />
                </Card>
              </Col>
              <Col
                xs={{ span: 24, order: 4 }}
                sm={{ span: 24, order: 4 }}
                md={{ span: 12, order: 4 }}
                lg={{ span: 12, order: 4 }}
                xl={{ span: 8, order: 5 }}
              >
                <Card
                  bodyStyle={{ padding: 0 }}
                  cover={
                    <img style={{ width: "100%" }} src={englishlearntypeImg} />
                  }
                >
                  <Card.Meta
                    style={metaStyle}
                    title="课程丰富多样"
                    description={
                      <div>
                        <ul>
                          <li>全球通用英语</li>
                          <li>雅思、托福课程</li>
                          <li>剑桥评估测试课程</li>
                          <li>大学衔接预备课程</li>
                          <li>全球专业商务课程</li>
                          <li>专业客户服务课程</li>
                          <li>航空公司机组人员培训课程</li>
                        </ul>
                      </div>
                    }
                  />
                </Card>
              </Col>
              <Col
                xs={{ span: 24, order: 6 }}
                sm={{ span: 24, order: 6 }}
                md={{ span: 12, order: 6 }}
                lg={{ span: 12, order: 6 }}
                xl={{ span: 8, order: 6 }}
              >
                <Card
                  bodyStyle={{ padding: 0 }}
                  cover={
                    <img style={{ width: "100%" }} src={englishlearntourImg} />
                  }
                >
                  <Card.Meta
                    style={metaStyle}
                    title="观光游览"
                    description="落基山之旅、白水漂流、惠斯勒旅行、维多利亚游览、温哥华岛之行等"
                  />
                </Card>
              </Col>
            </Row>
          </Panel>
          <Panel header="寄宿安排：也可自行安排食宿" key="accommodation">
            <List>
              <List.Item extra={<img width={272} src={accomodationImg} />}>
                <p>
                  <ul>
                    <li>住宿：可选单独房间 或 多人共享房间</li>
                    <li>
                      餐饮：可选一日三餐 或
                      一日两餐，参加学校活动时寄宿家庭可准备午餐盒 或
                      午休时间自行解决餐饮
                    </li>
                    <li>
                      交通：可选寄宿家庭接送去学校 或 接送至地铁站 或 自行乘公交
                      或 自行解决交通事宜
                    </li>
                    <li>
                      周末：可参与寄宿家庭的周末活动 或 参加学校组织的额外活动
                      或 自由活动
                    </li>
                  </ul>
                </p>
              </List.Item>
            </List>
          </Panel>
          <Panel header="其他说明" key="other">
            <List>
              <List.Item extra={<img width={100} src={wechatImg} />}>
                <p>
                  <ul>
                    <li>
                      课后活动和游览活动，选择不同日期活动会有所不同，详细情况请微信咨询
                    </li>
                    <li>
                      可提供机场接送、日常用车、民居酒店预定、机票预定、保险购买、签证办理等服务
                    </li>
                    <li>团队预定有适当优惠</li>
                  </ul>
                </p>
              </List.Item>
            </List>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
