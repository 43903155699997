import React, { Component } from "react";
import { Space } from "antd";

export default class Who extends Component {
  render() {
    return (
      <div>
        <Space direction="vertical" size="large">
          <div>
            留学纵然有很多好处，但也未必是所有人的选择，还是要根据个人实际情况综合考虑，下面罗列一些适合留学的人群特征：
          </div>
          <Space direction="vertical">
            <div className="title_small">孩子特征</div>
            <div>
              孩子对留学、出国游玩、新鲜事物有浓厚的兴趣，愿意尝试改变，适应能力强
            </div>
            <div>对英语有兴趣，愿意学习英语，英语成绩还不错</div>
            <div>思惟活跃，喜欢艺术、体育，兴趣爱好丰富</div>
            <div className="title_small">家庭情况</div>
            <div>
              家庭氛围宽松，愿意尝试改变，能承受一定的压力和变故，经济状况良好
            </div>
            <div>
              有异国亲属、朋友或工作上的合作关系，对海外事物和生活有一定的了解
            </div>
            <div>
              如果低龄孩子留学，有人能陪读，是最好的选择，家庭能承担这种分离
            </div>
          </Space>
          <div>
            以上所列只是加分项，其实最重要的是有“想留学”的念头，只要有了初心，就会不断地克服困难、创造条件，一步步地实现自己的愿望。
          </div>
        </Space>
      </div>
    );
  }
}
