import React, { Component } from "react";
import { Space, Row, Col, Image } from "antd";
import vancouverImg from "./images/vancouver.jpg";

export default class Vancouver extends Component {
  render() {
    return (
      <div>
        <div className="title">温哥华</div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Space size="large" direction="vertical">
              <div>
                我们常说的温哥华实际上是指整个大温哥华地区（Greater
                Vancouver），简称大温地区，
                主要包含加拿大第三大城市温哥华和周边同属都会区的城镇。
              </div>
              <div>
                大温地区位于加拿大的西海岸，濒临太平洋，东部是连绵的落基山脉。夏季气候干爽，冬季温暖多雨。
                由于东部连绵的落基山脉挡住了美洲大陆来的寒冷干燥气流，因此温哥华四季宜人，是全加拿大冬季最暖和的城市
                ，最冷的1月平均气温为3℃，7月的平均气温为17℃。
              </div>
              <div>
                根据2021年人口普查，大温哥华地区的人口为260万人左右，是不列颠哥伦比亚省以至加拿大西部最大的都会区，以及全国第三大都会区。
                温哥华是华裔人口最集中的北美城市之一，而温哥华华埠也是北美除旧金山以外最大的唐人街。
              </div>
              <div>
                温哥华港是加拿大最大的港口，温哥华地区亦是多家林木业和采矿业公司的总部所在，而近年软件开发、生物科技和电影业等行业的发展也相当蓬勃。
                温哥华更是北美继洛杉矶及纽约市后的第三大制片中心，素有“北好莱坞”（Hollywood
                North）的称号。
              </div>
              <div>
                温哥华四周风景宜人，是一个主要旅游点。市内的多个公园，加上环绕温市的高山、海洋和森林，以及区内的种族多样化，皆是吸引游客的卖点。
                北美著名滑雪胜地惠斯勒与温哥华距离约两小时车程，并与温哥华联手举办2010年冬季奥运会。
                温哥华也有一个大型邮轮码头，每年有超过100万人次在温哥华展开他们的邮轮假期（通常是前往阿拉斯加）。
              </div>
              <div>
                大温地区的教育资源丰富，
                其中的不列颠哥伦比亚大学（UBC），1908年创校，至今已培养出了8位诺贝尔奖得主，2022U.S.
                News世界大学排名第37名。
                1925年创校的艾米丽卡尔艺术与设计大学也是世界领先的艺术院校之一。
                此外，大温的不列颠哥伦比亚理工学院（BCIT）是省内第三大的高等教育机构，主要培训工程界和企业界的技术人员，提供职业导向的课程。
              </div>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Image src={vancouverImg} width={"100%"} style={{ padding: 10 }} />
          </Col>
        </Row>
      </div>
    );
  }
}
