import React, { Component } from "react";
import { Space, Button, Modal, Tag, Row, Col } from "antd";
import mainImg from "../../assets/images/going.jpg";
import Page from "./page";
import "./index.less";

export default class Going extends Component {
  state = {
    isModalVisible: false,
    word: "",
  };
  showModal = (word) => {
    this.setState({ isModalVisible: true, word });
  };
  hideModal = () => {
    this.setState({ isModalVisible: false, word: "" });
  };
  handleOk = () => {
    this.hideModal();
  };

  handleCancel = () => {
    this.hideModal();
  };
  render() {
    const { isModalVisible, word } = this.state;
    return (
      <div>
        <Row>
          <Col span={24}>
            <img src={mainImg} className="mainImg" />
          </Col>
        </Row>
        <div className="main">
          <Space wrap>
            <Tag
              color="magenta"
              className="tag"
              onClick={() => this.showModal("canada")}
            >
              加拿大
            </Tag>
            <Tag
              color="red"
              className="tag"
              onClick={() => this.showModal("vancouver")}
            >
              温哥华
            </Tag>
            <Tag
              color="volcano"
              className="tag"
              onClick={() => this.showModal("stanley")}
            >
              史丹利公园
            </Tag>
            <Tag
              color="orange"
              className="tag"
              onClick={() => this.showModal("granville")}
            >
              格兰维尔岛
            </Tag>
            <Tag
              color="gold"
              className="tag"
              onClick={() => this.showModal("gastown")}
            >
              煤气镇
            </Tag>
            <Tag
              color="lime"
              className="tag"
              onClick={() => this.showModal("vpl")}
            >
              温哥华公共图书馆
            </Tag>
            <Tag
              color="green"
              className="tag"
              onClick={() => this.showModal("steven")}
            >
              列治文渔人码头
            </Tag>
            <Tag
              color="cyan"
              color="green"
              className="tag"
              onClick={() => this.showModal("ubc")}
            >
              不列颠哥伦比亚大学（UBC）
            </Tag>
            <Tag
              color="blue"
              color="green"
              className="tag"
              onClick={() => this.showModal("burnaby- mountain")}
            >
              本拿比山公园
            </Tag>
            <Tag
              color="geekblue"
              color="green"
              className="tag"
              onClick={() => this.showModal("metrotown")}
            >
              铁道镇
            </Tag>
            <Tag
              color="purple"
              color="green"
              className="tag"
              onClick={() => this.showModal("canada-place")}
            >
              加拿大广场
            </Tag>
            <Tag
              color="#f50"
              color="green"
              className="tag"
              onClick={() => this.showModal("capilano")}
            >
              卡皮拉诺公园与吊桥
            </Tag>
            <Tag
              color="#2db7f5"
              color="green"
              className="tag"
              onClick={() => this.showModal("sfu")}
            >
              西门菲沙大学（SFU）
            </Tag>
            <Tag
              color="#87d068"
              color="green"
              className="tag"
              onClick={() => this.showModal("vancouver-outlets")}
            >
              温哥华奥特莱斯
            </Tag>
            <Tag
              color="#108ee9"
              color="green"
              className="tag"
              onClick={() => this.showModal("garry-point")}
            >
              加利角公园
            </Tag>
          </Space>
          <Modal
            title="行万里路"
            visible={isModalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key="cancel" onClick={this.handleCancel}>
                关闭
              </Button>,
            ]}
            destroyOnClose
            centered
            width={"80%"}
          >
            <Page word={word} />
          </Modal>
        </div>
      </div>
    );
  }
}
