import { combineReducers } from "redux";
import { SET_MENU } from "./action-types";

const initHZMenu = "/";
function hzMenu(state = initHZMenu, action) {
  switch (action.type) {
    case SET_MENU:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({ hzMenu });
