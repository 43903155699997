import React, { Component } from "react";
import { Space } from "antd";

export default class Why extends Component {
  render() {
    return (
      <div>
        <Space direction="vertical" size="large">
          <div>
            近年来，出国游学渐渐成为一种潮流，越来越多的人有过出国游学的思考和打算。百年来，从睁眼看世界到体验不同文化的碰撞，出国游学的目的已经变得多种多样了。
          </div>
          <div>
            在当今中国经济迅猛发展、疫情管理安全有序与西方各国的经济通胀、疫情躺平式管理形成鲜明对比的背景下，出国游学的话题似乎欲说还休。
          </div>
          <div>下面，我们共同探讨一下为何要游学：</div>
          <Space direction="vertical">
            <div className="title_small">接受最好的高等教育</div>
            <div>
              不可否认，在世界大学排名中，美国和西方发达国家的大学排名更靠前，数量也更占优势；西方大学采取“宽进严出”和鼓励“独立思考”，毕业生的整体收获更大一些；
            </div>
            <div className="title_small">体验不同的基础教育</div>
            <div>
              西方的基础教育大多是基于人文思想展开的，注重实践和分析能力，鼓励思想自由，时刻保持批判性思维；中国的基础教育更注重知识方法和技能的考察；
            </div>
            <div className="title_small">体会不同的教育理念</div>
            <div>
              西方教育是一种尝试教育，在尝试中学习、积累经验；中国教育是一种灌输式教育，将“先人”的经验告知学生，学生在经验的指导下学习实践；所以，西方教育是不怕犯错，鼓励冒险，中国教育是避免犯错，避免走弯路；
            </div>
            <div className="title_small">了解不同的异邦文化</div>
            <div>
              西方文化来源于海洋文明、基督教等宗教，鼓励追求个性的展示和冒险精神，中国文化来源于大陆文明和儒家思想，鼓励经验的传承和群体的协同发展；
            </div>
            <div className="title_small">更好的理解认识自己</div>
            <div>只有看到别人和自己的不同，才能更充分的认识自己</div>
            <div className="title_small">培养多元的全球视野</div>
            <div>
              了解不同的多元文化，掌握更多样的知识体系，才能以更宏观的视角看待这个世界，未来社会更需要国际型人才；
            </div>
            <div className="title_small">更好的提升完善自我</div>
            <div>
              摆脱“重复无产出”的刷题任务，用有限的青春时光培养出更多的有竞争力的能力
            </div>
          </Space>

          <div>
            其实，游学的目的不仅仅是有机会直接触摸另一种文化，体验不同的教育模式，更重要的是获得生活经历，这才是游学的最大魅力所在。
          </div>
          <div>生命是由无数的体验构成的，游学无疑是其中最美好的一个片段。</div>
        </Space>
      </div>
    );
  }
}
