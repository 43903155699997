import React, { Component } from "react";
import { Space, Row, Col, Image } from "antd";
import stanleyImg from "./images/stanley.jpg";

export default class Canada extends Component {
  render() {
    return (
      <div>
        <div className="title">史丹利公园</div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Space size="large" direction="vertical">
              <div>
                史丹利公园（Stanley
                Park）是一个位于加拿大不列颠哥伦比亚省温哥华的市区公园。
                公园面积有404.9公顷（1000英亩），是加拿大最大与北美第三大市立公园。
                公园每年吸引大概800万人次游览，包括本地居民和外地游客。
              </div>
              <div>
                一条8.8公里（5.5英里）长的路径环绕整个公园，以供游人缓步、骑脚踏车或玩滚轴溜冰；
                每年有大概250万人次使用此路径。园内大部分地带保留为树林，估计有大概50万棵树，
                部分更有76米（250英尺）高或已生长了数百年。
              </div>
              <div>
                史丹利公园与温哥华市中心近在咫尺，
                亦是温市地标之一狮门桥的南端落脚点所在，而温哥华水族馆亦座落园内。
                公园三面环水，而洛斯特湖则座落公园的南端。
              </div>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Image src={stanleyImg} width={"100%"} style={{ padding: 10 }} />
          </Col>
        </Row>
      </div>
    );
  }
}
