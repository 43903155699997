import React, { Component } from "react";
import { Space, Popover, Tag } from "antd";
import Canada from "./contents/canada";
import Vancouver from "./contents/vancouver";
import Stanley from "./contents/stanley";
import Metrotown from "./contents/metrotown";
import Gastown from "./contents/gastown";
import Granville from "./contents/granville";
import Vpl from "./contents/vpl";
import Ubc from "./contents/ubc";
import Sfu from "./contents/sfu";

export default class Page extends Component {
  render() {
    const word = this.props.word;
    switch (word) {
      case "canada":
        return <Canada />;
      case "vancouver":
        return <Vancouver />;
      case "stanley":
        return <Stanley />;
      case "metrotown":
        return <Metrotown />;
      case "gastown":
        return <Gastown />;
      case "vpl":
        return <Vpl />;
      case "granville":
        return <Granville />;
      default:
        return <div></div>;
    }
  }
}

const gastown = (
  <div>
    <div className="title">煤气镇</div>
    <Space size="large" direction="vertical">
      <div>
        煤气镇是温哥华最古老的一区，位于市中心。美丽整洁的街道矗立古雅的街灯，圆石路两旁经装修的旧屋，已经成为各种古董店、纪念品店、画廊及古雅的餐厅。位于Cambie及Water街交汇处有世界上唯一的蒸汽钟，到处洋溢着那个开拓时期的气息。
      </div>
    </Space>
  </div>
);
const robsonstreet = (
  <div>
    <div className="title">罗伯森街</div>
    <Space size="large" direction="vertical">
      <div>
        罗伯森街是温哥华的核心区域，也是商业区的起点，罗伯森街的尽头是象征着现代温哥华的加拿大大厦，它有高扬着五座白帆形的房顶，里面是综和宾馆、写字楼、会议厅，是1986年世界博览会的主体建筑。
      </div>
    </Space>
  </div>
);
const granvilleisland = (
  <div>
    <div className="title">格兰维尔岛</div>
    <Space size="large" direction="vertical">
      <div>
        格兰维尔岛以前原是工业区，以公众市场及美术学院为中心，是有着浓厚的文化气息的观光地，聚集了许多餐厅、酒廊、戏院、画廊、商店和“小童市场”。其中还有艺术家的Show-room及海鲜餐厅。
      </div>
    </Space>
  </div>
);
const stanleypark = (
  <div>
    <div className="title">斯坦利公园</div>
    <Space size="large" direction="vertical">
      <div>
        斯坦利公园是世界上最著名的城市公园之一，围绕着9千米的公园海傍小径，吸引了无数的骑车、跑步、溜冰人士及行人。公园内有海滩、湖泊、游乐园及野餐地点，公园中耸立着原住民所制的图腾柱，手工精细，文化气息浓厚，是游客必到拍照留念的地方。
      </div>
    </Space>
  </div>
);
const chinatown = (
  <div>
    <div className="title">唐人街</div>
    <Space size="large" direction="vertical">
      <div>
        温哥华其百年历史的唐人街是北美第二大唐人街（仅次于三藩市）。区内的房屋建筑设计融合了英国维多利亚时期及中国传统的古典风格。另外在区内的中山公园，是中国之外的中国古典花园。
      </div>
    </Space>
  </div>
);
const queenpark = (
  <div>
    <div className="title">伊丽莎白女皇公园</div>
    <Space size="large" direction="vertical">
      <div>
        伊丽莎白女皇公园是由一废置了的采石场所得之碎石而建成，是全市最高点。园内设有各种花园展览。它是温哥华最佳的结婚照户外拍摄场地，很多日本人特意到这里来拍摄结婚照。
      </div>
    </Space>
  </div>
);
const lionsgate = (
  <div>
    <div className="title">狮门大桥</div>
    <Space size="large" direction="vertical">
      <div>
        狮门大桥长1300米，高108米，为吉尼斯家族在1938年投资700万加元建造。狮门大桥北岸的富人区依山傍海，环境十分优美。
      </div>
    </Space>
  </div>
);
const capilanobridge = (
  <div>
    <div className="title">卡皮拉诺吊桥公园</div>
    <Space size="large" direction="vertical">
      <div>
        一条用大麻绳及香柏木结扎而成的吊索桥悬吊在加碧兰奴河的峡谷上，吊索桥公园里还有许多印第安的图腾柱和雕像。
      </div>
    </Space>
  </div>
);
const oceanmusume = (
  <div>
    <div className="title">海洋博物馆</div>
    <Space size="large" direction="vertical">
      <div>海洋博物馆是由一艘真正航过海的古老舰船所建盖而成。</div>
    </Space>
  </div>
);
const vancouvergallery = (
  <div>
    <div className="title">温哥华美术馆</div>
    <Space size="large" direction="vertical">
      <div>
        温哥华市美术馆内收藏着来自世界各地和温哥华的美术作品，美术馆本身建筑也是一件非凡的艺术品，是来自各国艺术家们最喜爱的游览圣地之一。
      </div>
    </Space>
  </div>
);
const MetroVancouver = (
  <div>
    <div className="title">大温哥华地区</div>
    <Space size="large" direction="vertical">
      <div>
        大温哥华地区（Metro
        Vancouver）是BC省的一个包含温哥华在内的都会区。又称大温地区或温哥华都会区。大温地区的最大城市是温哥华，行政中心则在本那比（Burnaby）。
      </div>
      <div>
        根据2006年人口普查，大温地区的人口有2,116,581，大概占BC省人口一半。大温地区的面积有2878.52
        平方公里，是省内人口密度最高的区域。
      </div>
      <div>
        大温地区包含21个市镇，主要包含温哥华（Vancouver），本拿比（Burnaby），列治文（Richmond），高贵林（Coquitlam），北温市（City
        of North Vancouver），西温（West
        Vancouver），素里（Surrey），三角洲（Delta），白石（White
        Rock），兰里市（ Langley City）等。
      </div>
      <div className="btext">气候</div>
      <div>
        温哥华全年气候温和，属温带海洋性气候。夏季气温一般在摄氏20℃左右，冬季气温在0℃以上。温哥华冬季很少下雪，但仍有较多的降雨量，为温哥华带来“加拿大雨都”之称，人们戏称“雨歌华”。
      </div>
      <div className="btext">饮食</div>
      <div>
        温哥华是一个由移民人口组成的大都会。早期的欧洲移民最先将法国菜、意大利菜、加勒比海菜以及地中海菜引入温哥华，令这里的欧洲菜达世界一流水准。近几十年，大量亚裔移民来到温哥华，更令本地饮食业大放异彩。身为加拿大第三大华裔聚居城市，温哥华拥有北美洲最高水平的中国菜。现在中国餐馆不再限于唐人街，而是遍布市内各区。近几年中国各大菜系和地方小吃，例如京菜、沪菜、粤菜、川菜，也在温哥华落地开花。此外，日本菜、韩国菜、越南菜和印度菜等都是颇受欢迎的亚洲美食，而且餐厅数目众多，选择丰富。另外，新加坡、马来西亚、菲律宾以及柬埔寨的餐饮，也非常受欢迎。
      </div>
      <div>
        加拿大的食品安全体系是世界上最好的，食品安全指数名列前茅。蔬菜肉类水果的价钱跟北京、上海的超市里差不多（如沃尔玛，家乐福），但是吃的比较放心，食品不用担心质量问题。大部分中国食品在中国超市都能买得到，甚至比国内更齐全，还有很多日本超市、韩国超市可供选择。
      </div>
      <div className="btext">交通</div>
      <div>
        机场 -
        温哥华国际机场位于市中心区的西南方15公里处，国内与国际航线连接加拿大及美国各大城市。机场与市区有地铁“加拿大线”（Canada
        Line）连接。
      </div>
      <div>
        市内交通 -
        温哥华的公交网络十分发达，搭乘公共交通可以抵达大部分市内目的地；公交系统主要包括公交（Blue
        Bus）、天车（SkyTrain）和公交船（SeaBus）；
      </div>
      <div>
        出租车 -
        在温哥华叫出租车通常要额外给予车资的15%作为小费，且出租车在温哥华并不常见，一般在机场、酒店等地方多一些，在路上不太容易叫到车子，需要时可打电话到出租车公司叫车；
      </div>
      <div className="btext">旅游</div>
      <div>
        温哥华依山傍海，林木苍翠，风景秀丽，拥有滑雪、狩猎、垂钓、航海等各种旅游资源。
      </div>
      <div>
        著名景点有：
        <Popover content={gastown} title={null} trigger="hover">
          <Tag color="gold">煤气镇</Tag>
        </Popover>
        、罗伯森街、格兰比岛、斯坦利公园、唐人街、伊丽莎白女皇公园、狮门大桥、加碧兰奴索吊桥公园、海洋博物馆、温哥华美术馆等等。
      </div>
    </Space>
  </div>
);
const BC = (
  <div>
    <div className="title">不列颠哥伦比亚省</div>
    <Space size="large" direction="vertical">
      <div>
        不列颠哥伦比亚省（British
        Columbia）是加拿大西部的一个省，又称英属哥伦比亚省、BC省、卑诗省等。
      </div>
      <div>
        不列颠哥伦比亚省的面积为944735平方千米。截至2011年，人口为4400057人，首府位于温哥华岛的维多利亚，最大城市是温哥华。
      </div>
      <div>不列颠哥伦比亚省气候温和，省内大部分面积是森林地带。</div>
    </Space>
  </div>
);
const vancouver = (
  <div>
    <div className="title">加拿大</div>
    <Space size="large" direction="vertical">
      <div>
        加拿大（Canada），位于北美洲北部，东临大西洋，西濒太平洋，西北部邻美国阿拉斯加州，南接美国本土，北靠北冰洋。加拿大国土面积998万平方千米，居世界第二位。截至2019年11月，加拿大共设10省3地区，首都为渥太华。截至2020年10月，加拿大人口为3800万。
      </div>
      <div>
        加拿大是高度发达的资本主义国家，是世界工业大国和西方七大工业国之一，制造业和高科技产业较发达，资源工业、初级制造业和农业为国民经济的主要支柱。
      </div>
      <div>
        加拿大的气候是大陆性气候，该国大部分处于极地，冬天长期严寒、夏天短暂、春季和秋季极短。落基山脉对来自太平洋的缓和气流起了屏障的作用，故只有落基山脉
        以西地区（不列颠哥伦比亚省），享有相对温和的气候。
      </div>
    </Space>
  </div>
);
