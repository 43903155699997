import React, { Component } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Image,
  message,
  Card,
  Button,
  Form,
  Input,
  List,
  // message,
} from "antd";
import mainImg from "../assets/images/contactus.jpg";
import mapImg from "../assets/images/map.jpg";
import "./pages.less";

export default class Contactus extends Component {
  formRef = React.createRef();
  onSubmit = (event) => {
    const formData = new FormData();
    formData.append("submit", "submit");
    formData.append(
      "sendbody",
      "页面提交内容：<br>用户名：" +
        event.username +
        "<br>联系信息：" +
        event.contactinfo +
        "<br>标题：" +
        event.titleinfo +
        "<br>内容：" +
        event.bodyinfo +
        "<br>留言时间：" +
        new Date()
    );
    axios
      .post("/php/sendemail.php", formData)
      .then((response) => {
        console.log(response.data);
        this.formRef.current.resetFields();
        message.success("信息提交成功！");
      })
      .catch((error) => {
        console.log(error);
        message.warn("信息提交失败！");
      });
  };
  onReset = () => {
    this.formRef.current.onReset();
  };
  render() {
    return (
      <div>
        <Row>
          <Col span={24}>
            <img src={mainImg} className="mainImg" />
          </Col>
        </Row>
        <div className="main">
          <Row gutter={16} wrap={true}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card
                title="联系方式"
                headStyle={{ color: "rgb(59, 99, 156)", fontWeight: "bold" }}
                bordered={false}
                hoverable={false}
              >
                {/* <Space direction="vertical">
                  <div>
                    地址： 上海市浦东新区张江镇秋月路26号矽岸国际4号楼2楼
                  </div>
                  <div>电话： 86-13331864059 , 1-604-653-9829</div>
                  <div>邮箱： info@haizongcc.com</div>
                  <div>Q Q： 2052881686</div>
                  <div>微信： haizongcc</div>
                </Space> */}
                <List>
                  <List.Item>电话： 86-13331864059 , 1-604-653-9829</List.Item>
                  <List.Item>邮箱： info@haizongcc.com</List.Item>
                  <List.Item>Q Q： 2052881686</List.Item>
                  <List.Item>咨询微信号： haizongcc</List.Item>
                  <List.Item>服务公众号： haizongcompany</List.Item>
                </List>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Card
                title="在线咨询"
                headStyle={{ color: "rgb(59, 99, 156)", fontWeight: "bold" }}
                bordered={false}
                hoverable={false}
              >
                <Form
                  name="basic"
                  style={{
                    maxWidth: 600,
                    // backgroundColor: "blue",
                  }}
                  layout={"horizontal"}
                  autoComplete="off"
                  ref={this.formRef}
                  onFinish={this.onSubmit}
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "请输入您的姓名",
                          },
                        ]}
                      >
                        <Input placeholder="请输入您的姓名" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="contactinfo"
                        rules={[
                          {
                            required: true,
                            message: "请输入联系方式",
                          },
                        ]}
                      >
                        <Input placeholder="请输入联系方式" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="titleinfo"
                    rules={[
                      {
                        required: true,
                        message: "主题",
                      },
                    ]}
                  >
                    <Input placeholder="主题" />
                  </Form.Item>
                  <Form.Item name="bodyinfo">
                    <Input.TextArea rows={4} placeholder="内容" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      提交
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            {/* <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Image src={mapImg} width={"100%"} style={{ padding: 10 }} />
            </Col> */}
          </Row>
          {/* <Row gutter={16}>
              <Col span={8}>
                <Card title="网络" bordered={false}>
                  <Space direction="vertical">
                    <div className="title_small"></div>
                    <div>网站 www.haizongcc.com</div>
                    <div>邮箱 info@haizongcc.com</div>
                    <div>QQ 2052881686</div>
                    <div>微信 haizongcc</div>
                  </Space>
                </Card>
              </Col>
              <Col span={8}>
                <Card title="上海" bordered={false}>
                  <Space direction="vertical">
                    <div className="title_small"></div>
                    <div>公司 上海海踪文化传播有限公司</div>
                    <div>电话 86-13331864059</div>
                    <div>地址 上海市浦东新区张江镇秋月路26号矽岸国际4幢2楼</div>
                    <div>邮编 201210</div>
                  </Space>
                </Card>
              </Col>
              <Col span={8}>
                <Card title="温哥华" bordered={false}>
                  <Space direction="vertical">
                    <div className="title_small"></div>
                    <div>公司 haizong company</div>
                    <div>电话 1-6046539829</div>
                    <div>地址 6850 Raleigh Street, Vancouver, BC, Canada</div>
                    <div>邮编 V5S2X1</div>
                  </Space>
                </Card>
              </Col>
            </Row> */}
        </div>
      </div>
    );
  }
}
