import React, { Component } from "react";
import { Collapse, Timeline, Space, Button, Modal, Tag, Row, Col } from "antd";
import mainImg from "../../assets/images/reading.jpg";
const { Panel } = Collapse;

export default class Reading extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col span={24}>
            <img src={mainImg} className="mainImg" />
          </Col>
        </Row>
        <div className="main">
          <div style={{ height: 300 }}>该栏目建设中，请稍后再来......</div>
        </div>
      </div>
      // <Collapse accordion>
      //   <Panel header="加拿大5-8年级必读书单" key="1">
      //     <Timeline>
      //       <Timeline.Item>Hatchet – Gary Paulsen</Timeline.Item>
      //       <Timeline.Item>
      //         The Adventure of Tom Sawyer – Mark Twain
      //       </Timeline.Item>
      //       <Timeline.Item>
      //         A Long Walk to Water – Park, Linda Sue
      //       </Timeline.Item>
      //       <Timeline.Item>A Wrinkle in Time – L’Engle, Madeline</Timeline.Item>
      //     </Timeline>
      //   </Panel>
      //   <Panel header="美国K年级最受欢迎的纸质书" key="0">
      //     <Timeline>
      //       <Timeline.Item>1. Dr. Seuss Collection [Dr. Seuss]</Timeline.Item>
      //       <Timeline.Item>
      //         2. Biscuit Series [Alyssa Satin Capucilli]
      //       </Timeline.Item>
      //       <Timeline.Item>3. Pete the Cat Series [James Dean]</Timeline.Item>
      //       <Timeline.Item>4. The Pigeon Series [Mo Willems]</Timeline.Item>
      //       <Timeline.Item>
      //         5. Elephant and Piggie Series [Mo Willems]
      //       </Timeline.Item>
      //       <Timeline.Item>
      //         6. The Very Hungry Caterpillar [Eric Carle]
      //       </Timeline.Item>
      //       <Timeline.Item>7. Big Egg [Molly Coxe]</Timeline.Item>
      //       <Timeline.Item>8. The Snowy Day [Ezra Jack Keats]</Timeline.Item>
      //       <Timeline.Item>9. David Series [David Shannon]</Timeline.Item>
      //       <Timeline.Item>
      //         10.Brown Bear, Brown Bear, What Do You See? [Bill Martin Jr.]
      //       </Timeline.Item>
      //       <Timeline.Item>11.Clifford Series [Norman Bridwell]</Timeline.Item>
      //       <Timeline.Item>
      //         12.Itchy, Itchy Chicken Pox [Grace Maccarone]
      //       </Timeline.Item>
      //     </Timeline>
      //   </Panel>
      // </Collapse>
    );
  }
}
