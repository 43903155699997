import React, { Component } from "react";
import { Space, Collapse, Tree } from "antd";

const { Panel } = Collapse;
const treeData_long = [
  {
    title: "小学、中学留学",
    key: "0",
    children: [
      {
        title: "公校",
        key: "0-0",
        children: [
          {
            title: (
              <div>
                <p>
                  最短半年以上，决定留学就最好读到中学毕业，5-17岁可申请
                  {/* 参见<a href="http://www.baidu.com">长期留学</a> */}
                </p>
              </div>
            ),
            key: "0-0-0",
          },
        ],
      },
      {
        title: "私校",
        key: "0-1",
        children: [
          {
            title: "申请要求高，费用高",
            key: "0-1-0",
          },
        ],
      },
    ],
  },
  {
    title: "大学、研究生留学",
    key: "1",
    children: [
      {
        title:
          "本科四年，硕士两年，温哥华还有一些不错的大专college，也可以办工签、移民",
        key: "1-0",
      },
    ],
  },
];
const treeData_short = [
  {
    title: "小学、中学留学体验课",
    key: "0",
    children: [
      {
        title: "公校",
        key: "0-0",
        children: [
          {
            title:
              "根据英语水平插班到本地班级，体验真实的北美教育，1个月，9-17岁可申请",
            key: "0-0-0",
          },
        ],
      },
    ],
  },
  {
    title: "语言学校",
    key: "1",
    children: [
      {
        title:
          "全日制英语学习，可选基础英语、雅思、托福等课程，适合想提高英语的中学生、成人，2-24周，13岁以上可申请",
        key: "1-0",
      },
    ],
  },
];
const treeData_summer = [
  {
    title: "小学、中学公校夏令营",
    key: "0",
    children: [
      {
        title:
          "公校教育局组织的夏令营，体验真实的北美教育，1-8周，9-17岁可申请",
        key: "0-0",
      },
    ],
  },
  {
    title: "语言学校夏令营",
    key: "1",
    children: [
      {
        title:
          "全日制英语学习+丰富的课外活动，学员更多样化，2-8周，13-18岁可申请",
        key: "1-0",
      },
    ],
  },
  {
    title: "高校夏令营",
    key: "2",
    children: [
      {
        title:
          "高校组织的夏令营，类型更丰富，演讲、领导力、艺术、体育等内容，英语要求高，1-8周，5-18岁可申请",
        key: "2-0",
      },
    ],
  },
  {
    title: "公益组织夏令营",
    key: "3",
    children: [
      {
        title:
          "公益组织的夏令营，以体育活动为主，一般都住在营地，大部分成员是本地孩子，1-8周，5-18岁可申请",
        key: "3-0",
      },
    ],
  },
];
const treeData_winter = [
  {
    title: "语言学校冬令营",
    key: "0",
    children: [
      {
        title:
          "全日制英语学习+丰富的课外活动，学员更多样化，2-6周，13-18岁可申请",
        key: "0-0",
      },
    ],
  },
];
export default class What extends Component {
  render() {
    return (
      <div>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div>以温哥华游学为例，下面介绍一下游学的形式：</div>
          <Collapse defaultActiveKey={["1"]}>
            <Panel
              header="长期留学"
              key="1"
              extra={
                <a href="../activities/" target="_blank">
                  近期活动查看
                </a>
              }
            >
              <Tree
                defaultExpandedKeys={["0-0-0", "0-0-1"]}
                treeData={treeData_long}
              />
            </Panel>
            <Panel
              header="短期体验游学"
              key="2"
              extra={
                <a href="../activities/" target="_blank">
                  近期活动查看
                </a>
              }
            >
              <Tree
                defaultExpandedKeys={["0-0-0", "0-0-1"]}
                treeData={treeData_short}
              />
            </Panel>
            <Panel
              header="夏令营"
              key="3"
              extra={
                <a href="../activities/" target="_blank">
                  近期活动查看
                </a>
              }
            >
              <Tree
                defaultExpandedKeys={["0-0-0", "0-0-1"]}
                treeData={treeData_summer}
              />
            </Panel>
            <Panel header="冬令营" key="4">
              <Tree
                defaultExpandedKeys={["0-0-0", "0-0-1"]}
                treeData={treeData_winter}
              />
            </Panel>
          </Collapse>
        </Space>
      </div>
    );
  }
}
