import React, { Component } from "react";
import { Space, Collapse, List, Card } from "antd";
import classImg from "../../../assets/images/activities/class.jpg";
import schedualImg from "../../../assets/images/activities/schedual.jpg";
import toursImg from "../../../assets/images/activities/tours.jpg";
import outdoorImg from "../../../assets/images/activities/outdoor.jpg";
import teachingImg from "../../../assets/images/activities/teaching.jpg";
import accomodationImg from "../../../assets/images/activities/accommodation.jpg";
import wechatImg from "../../../assets/images/haizongcompany.jpg";

export default class Careerlearning extends Component {
  onChange = (key) => {
    console.log(key);
  };
  render() {
    const { Panel } = Collapse;
    const gridStyle = {
      width: "33%",
      textAlign: "center",
    };
    const metaStyle = {
      padding: 10,
    };
    const specialData = [
      {
        title: "沉浸式英语学习",
        content: (
          <List size="small" bordered={false}>
            <List.Item>进入公立/语言学校学习</List.Item>
            <List.Item>专业认证的课程教师</List.Item>
            <List.Item>体验北美不同教育模式</List.Item>
            <List.Item>老师可进课堂观摩教学</List.Item>
          </List>
        ),
      },
      {
        title: "入住当地家庭",
        content: (
          <List size="small">
            <List.Item>体验真实加拿大生活</List.Item>
            <List.Item>提升跨文化交际能力</List.Item>
            <List.Item>感受加拿大多元文化</List.Item>
            <List.Item>开拓提升国际视野</List.Item>
          </List>
        ),
      },
      {
        title: "游览休闲总相宜",
        content: (
          <List size="small" split={false}>
            <List.Item>参观加拿大著名大学</List.Item>
            <List.Item>尝试本地户外活动</List.Item>
            <List.Item>游览温哥华特色景区</List.Item>
            <List.Item>领略不同的社区文化</List.Item>
          </List>
        ),
      },
    ];
    return (
      <div>
        <Collapse defaultActiveKey={["1"]} onChange={this.onChange}>
          <Panel header="职业留学" key="title">
            <p>
              与来自世界各地的同伴共同学习，实践中提升英语听力、口语和阅读技能。
              与当地家庭共同生活，了解加拿大的真实生活、欣赏迷人的北美风景。
              实用的英语技能的提升、朴实的多元文化，相信一定为您带来一段难忘的记忆。
            </p>
          </Panel>
          <Panel header="活动特色" key="special">
            <List
              grid={{
                gutter: 16,
                column: 3,
              }}
              dataSource={specialData}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    style={{
                      backgroundColor: "rgb(250,250,250)",
                    }}
                    headStyle={{
                      color: "rgb(59, 99, 156)",
                      fontWeight: "bold",
                    }}
                    hoverable={true}
                    bordered={false}
                    title={item.title}
                    size="small"
                  >
                    {item.content}
                  </Card>
                </List.Item>
              )}
            />
          </Panel>
          <Panel header="活动时间：2023年 7-8 月份" key="time">
            <p>
              <ul>
                <li>起始时间：2023年 7月2日 - 8月25日</li>
                <li>每周启动，1周 - 8周可选</li>
              </ul>
            </p>
          </Panel>
          <Panel header="活动城市：温哥华 / 维多利亚 / 多伦多" key="city">
            <p>加拿大 温哥华 / 维多利亚 / 多伦多</p>
          </Panel>
          <Panel header="适合人群：学生、家长" key="person">
            <p>
              <ul>
                <li>低龄儿童：6-12岁</li>
                <li>青 少 年：13-18岁</li>
                <li>成 年 人：大学生，家长，想学习成长的任何人</li>
              </ul>
            </p>
          </Panel>
          <Panel header="活动介绍" key="introduction">
            <Card>
              <Card.Grid style={gridStyle}>
                <Card cover={<img alt="example" src={classImg} />}>
                  <Card.Meta
                    title="分组学习"
                    style={metaStyle}
                    description={
                      <div>
                        <p>入学时进行英语水平测试</p>
                        <p>根据测试结果分班学习</p>
                      </div>
                    }
                  />
                </Card>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <Card cover={<img alt="example" src={classImg} />}>
                  <Card.Meta
                    title="时间安排"
                    style={metaStyle}
                    description={
                      <div>
                        <p>每周5天，8:30-9:00开始，3:00-4:00结束</p>
                        <p>周末：寄宿家庭活动 / 学校活动 / 自由活动</p>
                      </div>
                    }
                  />
                </Card>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <Card cover={<img alt="example" src={schedualImg} />}>
                  <Card.Meta
                    title="灵活学习安排"
                    style={metaStyle}
                    description={
                      <div>
                        <p>半天/全天学习 或 上午学习，下午活动 或</p>
                        <p>周一三五学习，周二四活动</p>
                      </div>
                    }
                  />
                </Card>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <Card cover={<img alt="example" src={outdoorImg} />}>
                  <Card.Meta
                    style={metaStyle}
                    title="户外活动"
                    description="郊游、高尔夫球、网球、划艇、远足、骑马、团体性运动、手工制作等；"
                  />
                </Card>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <Card cover={<img alt="example" src={toursImg} />}>
                  <Card.Meta
                    style={metaStyle}
                    title="短途游览"
                    description="盖斯镇、斯坦利公园、温哥华水族馆、卡普兰奴吊桥、不列颠哥仑比亚大学、人类学博物馆等"
                  />
                </Card>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                <Card cover={<img alt="example" src={teachingImg} />}>
                  <Card.Meta
                    style={metaStyle}
                    title="教学观摩研讨"
                    description="可定制教育主题探索，可观摩教学，了解BC省教学模式、课程设置原则、学生评估方式等"
                  />
                </Card>
              </Card.Grid>
            </Card>
          </Panel>
          <Panel header="寄宿安排：也可自行安排食宿" key="accommodation">
            <List>
              <List.Item extra={<img width={272} src={accomodationImg} />}>
                <p>
                  <ul>
                    <li>住宿：可选单独房间 或 多人共享房间</li>
                    <li>
                      餐饮：可选一日三餐 或
                      一日两餐，参加学校活动时寄宿家庭可准备午餐盒 或
                      午休时间自行解决餐饮
                    </li>
                    <li>
                      交通：可选寄宿家庭接送去学校 或 接送至地铁站 或 自行乘公交
                      或 自行解决交通事宜
                    </li>
                    <li>
                      周末：可参与寄宿家庭的周末活动 或 参加学校组织的额外活动
                      或 自由活动
                    </li>
                  </ul>
                </p>
              </List.Item>
            </List>
          </Panel>
          <Panel header="其他说明" key="other">
            <List>
              <List.Item extra={<img width={272} src={wechatImg} />}>
                <p>
                  <ul>
                    <li>
                      户外活动和游览活动，选择不同日期活动会有所不同，详细情况请微信咨询
                    </li>
                    <li>
                      可提供机场接送、日常用车、民居酒店预定、机票预定、保险购买、签证办理等服务
                    </li>
                    <li>团队预定有适当优惠</li>
                  </ul>
                </p>
              </List.Item>
            </List>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
