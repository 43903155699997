import React, { Component } from "react";
import { Collapse, List, Card, Row, Col } from "antd";
import leadershipschedualImg from "../../../assets/images/activities/leadershipschedual.jpg";
import leadershipcontentImg from "../../../assets/images/activities/leadershipcontent.jpg";
import wechatImg from "../../../assets/images/haizongcc.jpg";
import summerleadershipImg from "../../../assets/images/summerleadership.jpg";

export default class Summerlearningcamp extends Component {
  render() {
    const { Panel } = Collapse;
    return (
      <div>
        <Collapse defaultActiveKey={["title"]}>
          <Panel header='2023年加拿大暑期“全球领导力"夏令营' key="title">
            <List>
              <List.Item extra={<img width={150} src={summerleadershipImg} />}>
                <List.Item.Meta description="和我们一起住在世界知名的UBC大学，和来自世界各地的新朋友共同学习英语，培养领导技能，一起欣赏迷人的北美落基山脉风光。"></List.Item.Meta>
              </List.Item>
            </List>
          </Panel>
          <Panel header="活动特色" key="special">
            <Card>
              <List>
                <List.Item>
                  了解联合国所设定的一些目标，并根据每个目标从事一些小项目
                </List.Item>
                <List.Item>
                  提高学生对环境、社会和全球可持续发展和负责任的意识
                </List.Item>
                <List.Item>
                  除了学习，每日有傍晚活动，周末有旅行和娱乐活动
                </List.Item>
              </List>
            </Card>
          </Panel>
          <Panel header="活动时间：2023年 7月9日 - 8月12日" key="time">
            <p>
              <ul>
                <li>起始时间：2023年 7月9日 - 8月12日</li>
                <li>整个活动共5周，2周 - 5周可选</li>
              </ul>
            </p>
          </Panel>
          <Panel header="活动城市：温哥华" key="city">
            <p>加拿大 温哥华 UBC大学 校区内住宿和学习</p>
          </Panel>
          <Panel header="适合人群：13-17岁" key="person">
            <p>
              <ul>
                <li>青 少 年：13-17岁</li>
              </ul>
            </p>
          </Panel>
          <Panel header="活动介绍" key="introduction">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={14} xl={16}>
                <List split={false}>
                  <List.Item>
                    <Card
                      title="每周讨论的可持续发展目标"
                      style={{ width: "100%" }}
                    >
                      <div style={{ padding: 10 }}>
                        <p>第1周:可持续城市和社区</p>
                        <p>第2周:陆地生活</p>
                        <p>第3周:无贫困</p>
                        <p>第4周:优质教育</p>
                        <p>第5周:良好的健康和福祉</p>
                      </div>
                    </Card>
                  </List.Item>
                  <List.Item>
                    <Card title="傍晚活动" style={{ width: "100%" }}>
                      <div style={{ padding: 20 }}>
                        <p>
                          活动包括游戏、挑战之夜、
                          舞会等等。在每周的毕业典礼上，一些被选中的学生在整个项目中展示了领导能力，将获得机会赢得一些特殊奖项
                        </p>
                      </div>
                    </Card>
                  </List.Item>
                  <List.Item>
                    <Card title="周末活动" style={{ width: "100%" }}>
                      <div style={{ padding: 10 }}>
                        <p>
                          周末提供以探索城市及其周边地区为主题的旅行。旅行可能包括:游乐场，松鸡山，皮划艇
                          / 划水，卡皮拉诺吊桥等等
                        </p>
                      </div>
                    </Card>
                  </List.Item>
                </List>
              </Col>
              <Col xs={24} sm={24} md={12} lg={10} xl={8}>
                <img style={{ width: "100%" }} src={leadershipcontentImg} />
              </Col>
            </Row>
          </Panel>
          <Panel header="日程安排" key="accommodation">
            <p>
              <img width={"100%"} src={leadershipschedualImg} />
            </p>
          </Panel>
          <Panel header="其他说明" key="other">
            <List>
              <List.Item extra={<img width={100} src={wechatImg} />}>
                <p>
                  <ul style={{ lineHeight: 2 }}>
                    <li>
                      晚间活动和周末活动，可能会根据天气等原因有所调整，详细情况请微信咨询
                    </li>
                    <li>
                      可提供机场接送、日常用车、民居酒店预定、机票预定、保险购买、签证办理等服务
                    </li>
                    <li>团队预定有适当优惠</li>
                  </ul>
                </p>
              </List.Item>
            </List>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
