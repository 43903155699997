import React, { Component } from "react";
import { Row, Col, Image, Tabs } from "antd";
import mainImg from "../../assets/images/services.jpg";
import Why from "./why";
import When from "./when";
import Where from "./where";
import Who from "./who";
import What from "./what";
import How from "./how";
import "../pages.less";

const { TabPane } = Tabs;

export default class Services extends Component {
  render() {
    const paneStyle = { paddingLeft: 10 };
    return (
      <div>
        <Row>
          <Col span={24}>
            <img src={mainImg} className="mainImg" />
          </Col>
        </Row>
        <div className="main">
          <Tabs
            defaultActiveKey="why"
            tabPosition="left"
            size="small"
            tabBarStyle={{
              width: "15%",
              minWidth: 85,
              maxWidth: 150,
            }}
            style={{ marginLeft: -20 }}
          >
            <TabPane
              style={paneStyle}
              key="why"
              tab={<div className="tab_title tab_title_why">为何游学</div>}
            >
              <Why />
            </TabPane>
            <TabPane
              style={paneStyle}
              key="when"
              tab={<div className="tab_title tab_title_when">何时游学</div>}
            >
              <When />
            </TabPane>
            <TabPane
              style={paneStyle}
              key="where"
              tab={<div className="tab_title tab_title_where">何地游学</div>}
            >
              <Where />
            </TabPane>
            <TabPane
              style={paneStyle}
              key="who"
              tab={<div className="tab_title tab_title_who">何人游学</div>}
            >
              <Who />
            </TabPane>
            <TabPane
              style={paneStyle}
              key="what"
              tab={<div className="tab_title tab_title_what">何类游学</div>}
            >
              <What />
            </TabPane>
            <TabPane
              style={paneStyle}
              key="how"
              tab={<div className="tab_title tab_title_how">如何游学</div>}
            >
              <How />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
