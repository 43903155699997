import React, { Component } from "react";
import { Row, Col, Image, Card } from "antd";
import mainImg from "../../assets/images/activities.jpg";
import Summerlearningcamp from "./2023/summerlearningcamp";
import Summerleadershipcamp from "./2023/summerleadershipcamp";
import Summerlifeskillcamp from "./2023/summerlifeskillcamp";
import Summerparentscamp from "./2023/summerparentscamp";
import Shorttermlearning from "./2023/shorttermlearning";
import Longtermlearning from "./2023/longtermlearning";
import Careerlearning from "./2023/careerlearning";
import Englishlearning from "./2023/englishlearning";
import "../pages.less";

export default class Services extends Component {
  state = {
    activeTabKey: "summerlearningcamp",
  };
  onTabChange = (key) => {
    this.setState({ activeTabKey: key });
  };
  render() {
    const tabList = [
      {
        key: "summerlearningcamp",
        tab: '"语言学习+多元文化"夏令营',
      },
      {
        key: "summerleadershipcamp",
        tab: '"全球领导力"夏令营',
      },
      // {
      //   key: "summerlifeskillcamp",
      //   tab: '"户外生存体验"夏令营',
      // },
      {
        key: "summerparentscamp",
        tab: '"亲子寄宿"夏令营',
      },
      {
        key: "englishlearning",
        tab: "体验式游学",
      },
      // {
      //   key: "careerlearning",
      //   tab: "职业留学",
      // },
      {
        key: "shorttermlearning",
        tab: "短期游学",
      },
      {
        key: "longtermlearning",
        tab: "长期留学",
      },
    ];
    const contentList = {
      summerlearningcamp: <Summerlearningcamp />,
      summerleadershipcamp: <Summerleadershipcamp />,
      summerlifeskillcamp: <Summerlifeskillcamp />,
      summerparentscamp: <Summerparentscamp />,
      shorttermlearning: <Shorttermlearning />,
      longtermlearning: <Longtermlearning />,
      careerlearning: <Careerlearning />,
      englishlearning: <Englishlearning />,
    };
    const { activeTabKey } = this.state;
    return (
      <div>
        <Row>
          <Col span={24}>
            <img src={mainImg} className="mainImg" />
          </Col>
        </Row>
        <div className="main">
          <Card
            title="近期活动"
            style={{
              width: "100%",
              border: 0,
            }}
            bodyStyle={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={this.onTabChange}
          >
            {contentList[activeTabKey]}
          </Card>
        </div>
      </div>
    );
  }
}
